class Form {
	constructor(config = {}) {
    const {
			formContainerSelector,
			formFieldSelector,
			inFocusClass,
			fieldFilledClass,
			formFieldWrapper
		} = config;

    this.formContainerSelector = formContainerSelector || '.form';
    this.formFieldSelector = formFieldSelector || '.form__field'
    this.formFieldWrapper = formFieldWrapper || '.form__field-wrapper';
    this.inFocusClass = inFocusClass || 'in-focus';
    this.fieldFilledClass = fieldFilledClass || 'input-filled';

    this.form = document.querySelector(this.formContainerSelector);    
    if(this.form){
      const fields = this.form.querySelectorAll(this.formFieldSelector);
      this.fields = Array.prototype.slice.call(fields);
      // console.log(this.fields);
      this.evaluateField(this.fields); // on dom ready
      this.bindEvents();
    }
	}

	bindEvents() {
		this.fields.forEach(field => {
			field.addEventListener('change', e => {
				this.evaluateField(this.fields);
			});
			field.addEventListener('focus', e => {
				this.evaluateField(this.fields);
			});
			field.addEventListener('blur', e => {
				this.evaluateField(this.fields);
			});
		});
	}

	evaluateField(fields) {
    fields.forEach(field => {
      const fieldWrapper = field.closest(this.formFieldWrapper);
      if (field === document.activeElement) {
        fieldWrapper.classList.add('in-focus');
      } else {
        fieldWrapper.classList.remove('in-focus');
      }
      if(field.value == undefined || field.value == ''){
        fieldWrapper.classList.remove('input-filled');
      } else {
        fieldWrapper.classList.add('input-filled');
      }
      if(field.placeholder != ''){
        fieldWrapper.classList.add('input-filled');
      }
    });
	}
}

export default Form;
