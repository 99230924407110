class ImageStuff {
  constructor(config = {}) {
    const {
      bgContainerSelector,
    } = config;

    this.bgContainer = document.querySelector(bgContainerSelector);
    if(this.bgContainer){
      const img = this.bgContainer.getAttribute('data-img');
      console.log(img);
      this.bgContainer.style.backgroundImage = `url(${img})`;
    }
  }

}

export default ImageStuff;
