// Bundle theme module
// To add the classes to the bundle run gulp scripts (before initialisation)
import Menu from './Classes/Menu';
import ScrollSpy from './Classes/ScrollSpy';
import Form from './Classes/Form';
import Select from './Classes/Select';
import Helper from './Classes/Helper';
import ImageStuff from './Classes/ImageStuff';

(function() {
  document.addEventListener('DOMContentLoaded', function() {
    const img = new ImageStuff({
      bgContainerSelector: '.blur-section',
    });

    const select = new Select({
      selectSelector: '.category-select',
      stylingClass: 'category-select__select',
      optionsClass: 'category-select__options'
    });
    const form = new Form({
      formContainerSelector: '.comment-form'
    });
    const contactForm = new Form({
      formContainerSelector: '.contact-form'
    });
    const newsletterForm = new Form({
      formContainerSelector: '.newsletter-form'
    });
    const checkoutForm = new Form({
      formContainerSelector: '.woocommerce-checkout'
		});
		
    // code
    const catMenu = new Menu({
      menuElement: document.querySelector('.main-menu-container')
    });

    // const miniCart = new Menu({
    // 	hamburgerClass: 'js-mini-cart-toggler',
    // 	burgerAnimateClassIn: 'minicart-icon--in',
    // 	burgerAnimateClassOut: 'minicart-icon--out',
    // 	burgerTogglingContainerClassIn: 'minicart--in',
    // 	burgerTogglingContainerClassOut: 'minicart--out',
    // 	bodyMenuOpenClass: 'minicart-visible'
    // });

    const scrollSpy = new ScrollSpy({
      header: '.header',
      footer: '.sticky-foot'
    });

    if (document.querySelector('body.admin-bar')) {
      document.querySelector('html').classList.add('has-admin-bar');
    }

    const series = document.querySelector('.wp-post-series-name');
    if (series) {
      series.addEventListener('click', e => {
        const nav = series.parentElement.querySelector('.wp-post-series-nav');
        Helper.slideToggle(nav);
      });
    }
  });
})();
