class ScrollSpy {
	constructor(config = {}) {
		const { header, footer, backToTop } = config;

		this.header = document.querySelector(header || "header");
		this.footer = document.querySelector(footer || "footer");
		this.backToTop = document.querySelector(backToTop || ".js-back-to-top");

		const body = document.body;
		const html = document.documentElement;

		this.maxHeight = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);

		this.windowHeight = window.innerHeight;

		// console.log(this.header);
		// console.log(this.footer);

		this.lastScrollPos = 0;
		this.registerScroll();
		this.setMarginTop(window);
		this.bindEvents();

		if (this.maxHeight <= this.windowHeight) {
			// console.log("no scrolling possible");
			this.footer.classList.remove("js-footer--hide");
		}
	}

	bindEvents() {
		this.backToTop.addEventListener("click", e => {
			this.toTop();
		});
	}

	setMarginTop(window) {
		let el = this.header;

		function setMargin(el) {
			let height = el.offsetHeight;
			document.querySelector("body").style.marginTop = height + "px";
			document.querySelector(
				"body"
			).style.minHeight = `calc(100% - ${height}px)`;
		}

		setMargin(el);
		window.addEventListener("resize", e => {
			// console.log('resize');
			setMargin(el);
		});
	}

	registerScroll() {
		// console.log('registerscroll');
		window.addEventListener("scroll", e => {
			// console.log('scrolling');
			this.handleScroll(e);
		});
	}

	toTop(scrollDuration = 300) {
		const scrollHeight = window.scrollY;
		const scrollStep = Math.PI / (scrollDuration / 15);
		const cosParameter = scrollHeight / 2;
		let scrollCount = 0;
		let scrollMargin;
		let scrollInterval = setInterval(function() {
			if (window.scrollY != 0) {
				scrollCount = scrollCount + 1;
				scrollMargin =
					cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
				window.scrollTo(0, scrollHeight - scrollMargin);
			} else clearInterval(scrollInterval);
		}, 15);
	}

	handleScroll(e) {
		// console.log("handlescroll");
		let scrollTop = e.currentTarget.pageYOffset;
		let element = e.target;
		let height = this.header.offsetHeight;
		const f = this.footer;
		const h = this.header;

		if (this.lastScrollPos < height) {
			this.lastScrollPos = scrollTop;
			// console.log('scroll within top area before scrolling stuff happens');
			h.classList.add("js-header--full");
			h.classList.remove("js-header--hide");
			f.classList.add("js-footer--hide");
			f.classList.remove("js-footer--show");
		}

		if (this.lastScrollPos < scrollTop) {
			this.lastScrollPos = scrollTop;
			// console.log('scrolled down enough to have scrolling stuff happen');
			h.classList.add("js-header--hide");
			h.classList.remove("js-header--full");
			f.classList.add("js-footer--show");
			f.classList.remove("js-footer--hide");
		}

		if (this.lastScrollPos > scrollTop) {
			this.lastScrollPos = scrollTop;
			// console.log('scroll up');
			h.classList.add("js-header--full");
			h.classList.remove("js-header--hide");
			f.classList.add("js-footer--show");
			f.classList.remove("js-footer--hide");
		}

		if (this.maxHeight - this.windowHeight - 1 <= scrollTop) {
			this.lastScrollPos = scrollTop;
			// console.log('bottom of page');
			h.classList.add("js-header--full");
			h.classList.remove("js-header--hide");
			f.classList.add("js-footer--show");
			f.classList.remove("js-footer--hide");
		}
	}
}

export default ScrollSpy;
