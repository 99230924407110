import Helper from './Helper';

class Select {
	constructor(config = {}) {
		const {
			selectSelector,
			stylingClass,
			optionsClass,
		} = config;

		this.selectSelector = selectSelector || '.select';
		this.stylingClass = stylingClass || 'styledSelect';
		this.optionsClass = optionsClass || 'options';
		this.bindEvents();
	}


	bindEvents() {
		const stylerDivs = Array.prototype.slice.call(
			document.querySelectorAll('.' + this.stylingClass)
		);

		const options = Array.prototype.slice.call(
			document.querySelectorAll('.' + this.optionsClass)
		);

		stylerDivs.forEach(div => {
			div.addEventListener('click', e => {
				e.stopPropagation();
				this.openDropdown(div);
			});
		});

		document.addEventListener('click', e => {
			stylerDivs.forEach(div => {
				this.closeDropdown(div);
			});
		});

		options.forEach(option => {
			option.addEventListener('click', e => {
				e.stopPropagation();
				this.chooseSelect(option, e.target);
			});
    });    
	}

	openDropdown(div) {
		if (!div.classList.contains('active')) {
			div.classList.add('active');
			Helper.slideDown(div.nextSibling);
		}
	}

	closeDropdown(div) {
		div.classList.remove('active');
		Helper.slideUp(div.nextSibling);
	}

	chooseSelect(option, target) {
    const selectDiv = option.previousSibling;
		selectDiv.innerText = target.innerText;
    this.closeDropdown(selectDiv);
	}
}

export default Select;
