String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};


class Helper {
	static toggleClass(el, className) {
		if (!el || !className) {
			return;
		}

		var classString = el.className,
			nameIndex = classString.indexOf(className);
		if (nameIndex == -1) {
			classString += ' ' + className;
		} else {
			classString =
				classString.substr(0, nameIndex) +
				classString.substr(nameIndex + className.length);
		}
		el.className = classString;
	}

	static removeFromDom(elem) {
		elem.parentNode.removeChild(elem);
	}

	static replaceInDom(toReplace, replacement) {
		if (replacement) {
			let replacementObj = new DOMParser().parseFromString(
				replacement,
				'text/html'
			).body.childNodes;
			replacementObj = replacementObj.item(0);
			replacementObj.classList.add('teaser-fade-in');
			replacementObj.classList.add('teaser-animation');
			toReplace.parentNode.replaceChild(replacementObj, toReplace);
			setTimeout(function() {
				replacementObj.classList.remove('teaser-fade-in');
			}, 200);
		}
	}

	static appendInDom(toAppend, appendent) {
		if (appendent) {
			let appendObj = new DOMParser().parseFromString(appendent, 'text/html')
				.body.childNodes;
			appendObj = appendObj.item(0);
			appendObj.classList.add('teaser-fade-in');
			appendObj.classList.add('teaser-animation');
			toAppend.parentNode.appendChild(appendObj);
			setTimeout(function() {
				appendObj.classList.remove('teaser-fade-in');
			}, 200);
		}
	}

	static hasClass(el, className) {
		if (el.classList) return el.classList.contains(className);
		return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
	}

	static addClass(el, className) {
		if (el.classList) el.classList.add(className);
		else if (!hasClass(el, className)) el.className += ' ' + className;
	}

	static removeClass(el, className) {
		if (el.classList) el.classList.remove(className);
		else if (hasClass(el, className)) {
			var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
			el.className = el.className.replace(reg, ' ');
		}
	}

	static getClosest(elem, selector) {
		// Element.matches() polyfill
		if (!Element.prototype.matches) {
			Element.prototype.matches =
				Element.prototype.matchesSelector ||
				Element.prototype.mozMatchesSelector ||
				Element.prototype.msMatchesSelector ||
				Element.prototype.oMatchesSelector ||
				Element.prototype.webkitMatchesSelector ||
				function(s) {
					var matches = (this.document || this.ownerDocument).querySelectorAll(
							s
						),
						i = matches.length;
					while (--i >= 0 && matches.item(i) !== this) {}
					return i > -1;
				};
		}

		// Get the closest matching element
		for (; elem && elem !== document; elem = elem.parentNode) {
			if (elem.matches(selector)) return elem;
		}
		return null;
	}

	/**
	 * SlideUp
	 *
	 * @param {HTMLElement} element
	 * @param {Number} duration
	 * @returns {Promise<boolean>}
	 */
	static slideUp(element, duration = 500) {
		return new Promise(function(resolve, reject) {
			element.style.height = element.offsetHeight + 'px';
			element.style.transitionProperty = `height, margin, padding`;
			element.style.transitionDuration = duration + 'ms';
			element.offsetHeight;
			element.style.overflow = 'hidden';
			element.style.height = 0;
			element.style.paddingTop = 0;
			element.style.paddingBottom = 0;
			element.style.marginTop = 0;
			element.style.marginBottom = 0;
			window.setTimeout(function() {
				element.style.display = 'none';
				element.style.removeProperty('height');
				element.style.removeProperty('padding-top');
				element.style.removeProperty('padding-bottom');
				element.style.removeProperty('margin-top');
				element.style.removeProperty('margin-bottom');
				element.style.removeProperty('overflow');
				element.style.removeProperty('transition-duration');
				element.style.removeProperty('transition-property');
				resolve(false);
			}, duration);
		});
	}

	/**
	 * SlideDown
	 *
	 * @param {HTMLElement} element
	 * @param {Number} duration
	 * @returns {Promise<boolean>}
	 */
	static slideDown(element, duration = 500) {
		return new Promise(function(resolve, reject) {
			element.style.removeProperty('display');
			let display = window.getComputedStyle(element).display;

			if (display === 'none') display = 'block';

			element.style.display = display;
			let height = element.offsetHeight;
			element.style.overflow = 'hidden';
			element.style.height = 0;
			element.style.paddingTop = 0;
			element.style.paddingBottom = 0;
			element.style.marginTop = 0;
			element.style.marginBottom = 0;
			element.offsetHeight;
			element.style.transitionProperty = `height, margin, padding`;
			element.style.transitionDuration = duration + 'ms';
			element.style.height = height + 'px';
			element.style.removeProperty('padding-top');
			element.style.removeProperty('padding-bottom');
			element.style.removeProperty('margin-top');
			element.style.removeProperty('margin-bottom');
			window.setTimeout(function() {
				element.style.removeProperty('height');
				element.style.removeProperty('overflow');
				element.style.removeProperty('transition-duration');
				element.style.removeProperty('transition-property');
			}, duration);
		});
	}

	/**
	 * SlideToggle
	 *
	 * @param {HTMLElement} element
	 * @param {Number} duration
	 * @returns {Promise<boolean>}
	 */
	static slideToggle(element, duration = 500) {
		// console.log(element);
		// console.log(window.getComputedStyle(element).display);

		if (window.getComputedStyle(element).display === 'none') {
			return this.slideDown(element, duration);
		} else {
			return this.slideUp(element, duration);
		}
	}

	static setCookie(name, value, days) {
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			var expires = '; expires=' + date.toGMTString();
		} else var expires = '';
		document.cookie = name + '=' + value + expires + '; path=/';
	}

	static getCookie(cname) {
		var name = cname + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	static removeCookie(name) {
		this.setCookie(name, '', -1);
	}

	static replaceSpecialCharsWithUnderscore(string) {
		let stringToReplace = string.split(' ').join('_');
		return (stringToReplace = stringToReplace.replace(
			/[`~!@#$%^&*()|+\-=?;:äöüéàèÊÈÖÄÜó",.<>\{\}\[\]\\\/]/gi,
			'_'
		));
	}

	static wrap(el, wrapper) {
		el.parentNode.insertBefore(wrapper, el);
		wrapper.appendChild(el);
  }
  
  // example
  // var newEl = document.createElement('div');
  // newEl.innerHTML = '<p>Hello World!</p>';

  // var ref = document.querySelector('div.before');

  // insertAfter(newEl, ref);

  static insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
  
  
  // // example
  // var newEl = document.createElement('div');
  // newEl.innerHTML = '<p>Hello World!</p>';
  
  // var ref = document.querySelector('div.before');
  
  // insertBefore(newEl, ref);
  static insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }

}

export default Helper;
