import Helper from './Helper';

class Menu {
	constructor(config = {}) {
		const {
			hamburgerClass,
			menuElement,
			burgerAnimateClassIn,
			burgerAnimateClassOut,
			burgerTogglingContainerClassIn,
			burgerTogglingContainerClassOut,
			bodyMenuOpenClass
		} = config;

		this.hamburgerClass = hamburgerClass || 'js-hamburger';
		this.menuElement = menuElement || document.querySelector('.main-menu');
		this.burgerAniIn = burgerAnimateClassIn || 'animate--in';
		this.burgerAniOut = burgerAnimateClassOut || 'animate--out';
		this.containerAniIn = burgerTogglingContainerClassIn || 'overlay--in';
		this.containerAniOut = burgerTogglingContainerClassOut || 'overlay--out';
		this.bodyMenuOpenClass = bodyMenuOpenClass || 'menu-open';

		this.hamburger = document.querySelectorAll(`.${this.hamburgerClass}`);
		this.togglerArray = Array.prototype.slice.call(this.hamburger);
		this.bindEvents();
	}

	bindEvents() {
		this.togglerArray.forEach(burger =>
			burger.addEventListener('click', e => {
				this.toggleHamburger(
					burger,
					this.burgerAniIn,
					this.burgerAniOut,
					this.containerAniIn,
					this.containerAniOut
				);
			})
		);
	}

	toggleHamburger(
		burger,
		burgerAniIn,
		burgerAniOut,
		containerAniIn,
		containerAniOut
	) {
		const toggleContainer = burger.getAttribute('data-toggles-nav');
		// console.log(toggleContainer);
		// console.log(
		// 	`${this.hamburgerClass}[data-toggles-nav="${toggleContainer}"]`
		// );
		const burgers = document.querySelectorAll(
			`.${this.hamburgerClass}[data-toggles-nav="${toggleContainer}"]`
		);
		const burgerArray = Array.prototype.slice.call(burgers);
		const menu = document.querySelector(`.${toggleContainer}`);
		// console.log(burgers);
		// console.log(burgerArray);

		if (Helper.hasClass(menu, containerAniIn)) {
			Helper.removeClass(
				document.querySelector('html'),
				this.bodyMenuOpenClass
			);
			Helper.removeClass(menu, containerAniIn);
			Helper.addClass(menu, containerAniOut);

			burgerArray.forEach(burger => {
        const parent = burger.parentElement;
				Helper.addClass(parent, containerAniOut);
				Helper.removeClass(parent, containerAniIn);
				Helper.removeClass(burger, burgerAniIn);
				Helper.addClass(burger, burgerAniOut);
			});
		} else {
			Helper.addClass(document.querySelector('html'), this.bodyMenuOpenClass);
			Helper.addClass(menu, containerAniIn);
			Helper.removeClass(menu, containerAniOut);

			burgerArray.forEach(burger => {
				const parent = burger.parentElement;
				Helper.addClass(parent, containerAniIn);
				Helper.removeClass(parent, containerAniOut);
				Helper.addClass(burger, burgerAniIn);
				Helper.removeClass(burger, burgerAniOut);
			});
		}
	}

	killMenu() {
		// console.log('dead');
		let menu = this.element.parentElement.parentElement;
		let burger = this.hamburger;

		Helper.removeClass(burger, 'animate--in');
		Helper.removeClass(menu, 'submenu--in');
		Helper.addClass(burger, 'animate--out');

		if (menu.querySelector(`.menu__item--ancestor-open`) !== null) {
			Helper.slideUp(menu.querySelector('.menu--submenu-open'), 200);
			menu
				.querySelector(`.menu__item--ancestor-open`)
				.classList.remove('menu__item--ancestor-open');
			menu
				.querySelector(`.menu--submenu-open`)
				.classList.remove('menu--submenu-open');
		}

		Helper.removeClass(document.querySelector('body'), 'menu-open');
	}
}

export default Menu;
